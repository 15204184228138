/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import CreateRecipeForm from './recipe/CreateRecipeForm';
import RecipesList from './recipe/RecipesList';
import CreateMaterialForm from './material/CreateMaterialForm';
import MaterialList from './material/MaterialList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      style={{ width: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingLeft={3} width="100%">
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

function MenuTabs() {
  const [value, setValue] = React.useState(0);

  const handleTabChanged = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      flexGrow: 1, display: 'flex', minHeight: '100vh', minWidth: '100%',
    }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleTabChanged}
        aria-label="menu-tabs"
        sx={{
          borderRight: 1, borderColor: 'gray', minWidth: 100, maxWidth: 100,
        }}

      >
        <Tab label="Skapa recept" {...a11yProps(0)} />
        <Tab label="Recept" {...a11yProps(1)} />
        <Tab label="Skapa material" {...a11yProps(2)} />
        <Tab label="Material" {...a11yProps(3)} />

      </Tabs>
      <section style={{
        display: 'flex', justifyContent: 'center', width: '80%', overflowX: 'auto',
      }}
      >
        <TabPanel value={value} index={0}>
          <CreateRecipeForm edit={false} recipe={null} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RecipesList />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CreateMaterialForm edit={false} material={null} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <MaterialList />
        </TabPanel>
      </section>
    </Box>
  );
}

export default MenuTabs;
