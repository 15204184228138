/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { useMsal } from '@azure/msal-react';
import {
  Button,
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import * as React from 'react';
import { getIdToken } from '../../helpers/helper';
import { RecipeModel, recipeSubCategories } from '../../Models/RecipeModel';
import { deleteRecipe, getAllRecipes } from '../../services/recipes/RecipeService';
import CreateRecipeForm from './CreateRecipeForm';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function RecipesList() {
  const [recipes, setRecipes] = React.useState<RecipeModel[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [recipeToEdit, setRecipeToEdit] = React.useState<RecipeModel | null>(null);
  const msal = useMsal();

  async function getRecipes() {
    const r = await getAllRecipes(await getIdToken(msal.instance, msal.accounts[0]));
    setRecipes(r);
  }

  React.useEffect(() => {
    getRecipes();
  }, []);

  const onDeleteClicked = async (id: string) => {
    const confirmed = confirm('Är du säker på att du vill ta bort detta recept?');
    if (confirmed) {
      setLoading(true);
      const response = await deleteRecipe(id, await getIdToken(msal.instance, msal.accounts[0]));
      setLoading(false);
      // msal.instance.token;
      if (response.ok) {
        alert('Recept borttaget');
        getRecipes();
      } else {
        alert(`Något gick fel: ${await response.text()}`);
      }
    }
  };

  const onEditClicked = (recipe: RecipeModel) => {
    setRecipeToEdit(recipe);
  };

  if (loading) return <div style={{ color: '#fff' }}>Laddar...</div>;

  if (recipeToEdit) return <CreateRecipeForm edit recipe={recipeToEdit} onSaveClicked={() => { setRecipeToEdit(null); getRecipes(); }} />;

  return (
    <section className="container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ color: '#fb8c00' }} />
            <TableCell style={{ color: '#fb8c00' }}>Titel</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Beskrivning</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Kategori</TableCell>
            <TableCell style={{ color: '#fb8c00' }}>Skapat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recipes.map((recipe) => (
            <TableRow key={recipe.id}>
              <TableCell style={{ color: '#fff', width: 10 }}>
                <Button onClick={() => onEditClicked(recipe)}><EditIcon /></Button>
                {' '}
                <Button onClick={() => onDeleteClicked(recipe.id)} href="#"><DeleteIcon style={{ width: 20 }} /></Button>
              </TableCell>
              <TableCell style={{ color: '#fff' }}>{recipe.title}</TableCell>
              <TableCell style={{ color: '#fff' }}>{recipe.description}</TableCell>
              <TableCell style={{ color: '#fff' }}>{recipeSubCategories.find((x) => x.id === recipe.subCategoryId)?.value}</TableCell>
              <TableCell style={{ color: '#fff' }}>{new Date(recipe.createdUtc).toISOString().split('T')[0]}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </section>
  );
}

export default RecipesList;
