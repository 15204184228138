/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import * as React from 'react';
import TextField from '@mui/material/TextField/TextField';
import {
  Ingredient, RecipeModel, recipeSubCategories, RecipeSubCategoryIdEnum,
} from '../../Models/RecipeModel';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Button from '@mui/material/Button/Button';
import { upsertRecipe } from '../../services/recipes/RecipeService';
import { useMsal } from '@azure/msal-react';
import { getIdToken } from '../../helpers/helper';
import { Link } from '@mui/material';
import IngredientsForm from './IngredientsForm';
import DescriptionForm from '../common/DescriptionForm';
import AddImage from '../common/AddImage';
import ImageService from '../../services/common/ImageService';
import RecipeOverviewForm from './RecipeOverviewForm';

const emptyState = {
  id: '',
  imgUrl: '',
  title: '',
  description: '',
  ingredients: [],
  subCategoryId: null,
  tags: [],

  cookingTime: { amount: 0, measure: '' },
  toCookFor: 0,
  ovenCooking: null,

  createdUtc: new Date(),
} as RecipeModel;

interface Props {
  edit: boolean;
  recipe: RecipeModel | null;
  onSaveClicked?: () => void;
}

function CreateRecipeForm(props: Props) {
  const { edit, recipe, onSaveClicked } = props;
  const [state, setState] = React.useState<RecipeModel>(recipe || emptyState);
  const [recipeImg, setRecipeImg] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [savedRecipe, setSavedRecipe] = React.useState<boolean>(false);

  const msal = useMsal();

  // eslint-disable-next-line max-len
  const onInputChange = (name: keyof RecipeModel) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [name]: event.target.value });
  };

  const onImageChanged = (file: File | null) => {
    setRecipeImg(file);
    if (file) { setState({ ...state, imgUrl: '' }); }
  };

  const onSubmitClicked = async () => {
    setSavedRecipe(false);
    setLoading(true);

    let imgUrl = '';
    if (!state.imgUrl) {
      imgUrl = await ImageService.createImage(recipeImg as File, await getIdToken(msal.instance, msal.accounts[0]));
    } else {
      imgUrl = state.imgUrl;
    }

    const response = await upsertRecipe({ ...state, imgUrl }, edit, await getIdToken(msal.instance, msal.accounts[0]));
    setLoading(false);

    if (response.ok) {
      setState(emptyState);
      setSavedRecipe(true);
      alert('Recept sparat');
      if (onSaveClicked) { onSaveClicked(); }
    } else {
      alert(`Något gick fel: ${await response.text()}`);
    }
  };

  return loading ? <div style={{ color: '#fff' }}>Laddar...</div>
    : (
      <>
        <h4 style={{ color: '#fb8c00' }}>Skapa Recept</h4>
        {savedRecipe ? <Link href="https://babyforum.nu/" style={{ marginBottom: 20, display: 'block' }}>Se det nya receptet</Link> : null}
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 20, maxWidth: '100%',
        }}
        >
          <AddImage onImageChanged={(file: File | null) => onImageChanged(file)} existingImgUrl={edit ? state.imgUrl : ''} buttonTitle="Recept bild" />
          <TextField
            color="primary"
            onChange={onInputChange('title')}
            label="Title"
            value={state.title}
          />
          <IngredientsForm existingIngredients={edit ? state.ingredients : null} onIngredientsChange={(ingredients: Ingredient[]) => setState({ ...state, ingredients })} />
          <DescriptionForm title="Skapa beskrivning" existingDescription={state.description} ingredients={state.ingredients} onDescriptionChanged={(description: string) => setState({ ...state, description })} />
          <RecipeOverviewForm recipe={state} onRecipeOverviewChanged={(propertyName, value) => { setState({ ...state, [propertyName]: value }); }} />

          <FormControl>
            <InputLabel>Sub categories</InputLabel>
            <Select variant="outlined" label="Sub categories" value={state.subCategoryId === null ? '' : state.subCategoryId} onChange={(event) => setState({ ...state, subCategoryId: event.target.value as RecipeSubCategoryIdEnum })}>
              {recipeSubCategories.map((v) => <MenuItem key={v.id} value={v.id}>{v.value}</MenuItem>)}
            </Select>
          </FormControl>
          <Button variant="outlined" type="submit" onClick={() => onSubmitClicked()}>Save</Button>
        </div>
      </>
    );
}

export default CreateRecipeForm;
